import { Fragment } from 'react'

import { PayPalScriptProvider } from "@paypal/react-paypal-js"
import TopNavBar from "./components/TopNavBar"
import Footer from "./components/Footer"

import IrisRouter from './components/IrisRouter'

function App() {
  return (
    <Fragment>
      <PayPalScriptProvider options={{ "clientId": "AXATzuWirjk_iG46ZXFRF6Piblr5EOHPZl2N27o3ADhNw2WSA9b1Qej57dQKZG5mOLcoS3GypgndFjAS" , "currency": "USD", "intent": "authorize"}}>
        <TopNavBar />
        <IrisRouter />
        <Footer />
      </PayPalScriptProvider>
    </Fragment>
  )
}

export default App;
