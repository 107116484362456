import { useState, Fragment } from 'react'
import Dialog from '@mui/material/Dialog'
import Typography from '@mui/material/Typography'
import DialogContent from '@mui/material/DialogContent'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'

import ModalExitButton from './ModalExitButton'
import TextGiftCodeModal from './TextGiftCodeModal'

import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'

import './Modal.css'

type Props = {
    modalOpen: boolean
    setModalOpen: Function
    giftCodeStatus: string
    giftCode?: string
    numMessages: number
    resetState: Function
}

const GiftCodeStatusModal:React.FC<Props> = ({
    modalOpen,
    setModalOpen,
    giftCodeStatus,
    giftCode,
    numMessages,
    resetState
}) => {
    const [textGiftCodeModalOpen, setTextGiftCodeModalOpen] = useState<boolean>(false)

    const handleClose = () => {
        resetState()
        setModalOpen(false)
    }
    
    return (
        <Fragment>
            <Dialog
                fullWidth={true}
                maxWidth='lg'
                open={modalOpen}
                onClose={() => {}}
            >
                <DialogContent>
                    <Grid container spacing={1} sx={{height: '85vh', paddingX: '0px'}}>
                        <Grid item xs={12} sx={{textAlign: 'center', paddingBottom: '30px'}}>
                            {
                                giftCodeStatus === "PENDING" ?
                                    <Fragment>
                                        <Typography variant="h4" gutterBottom color={"#193959"} sx={{marginBottom: "25px", textAlign: 'center', marginTop: '1vh'}}>
                                            Processing gift order...
                                        </Typography>
                                        <img src={require('../media/GiftSquirrel3.png')} width='200px' alt='Squirrel under an oak tree'/>
                                    </Fragment>
                                : giftCode && giftCodeStatus === "SUCCESS" ? 
                                    <Fragment>
                                        <Typography variant="h4" gutterBottom color={"#193959"} sx={{marginBottom: "10px", textAlign: 'center', marginTop: '1vh'}}>
                                            Success!
                                        </Typography>
                                        <Typography variant="body1" gutterBottom sx={{textAlign: 'center'}}>
                                            Your printable gift card is below. Print this page, cut it out, and give it to a friend!<br />
                                            If you opted to receive your gift code via email, this will also be sent in an email.
                                        </Typography>
                                        <div
                                            style={{
                                                borderWidth: "2px",
                                                borderStyle: "solid",
                                                width: "fit-content",
                                                marginLeft: "auto",
                                                marginRight: "auto",
                                                marginTop: "30px",
                                                padding: "20px",
                                                paddingTop: "10px",
                                                paddingBottom: "10px"
                                            }}
                                        >
                                            <Typography variant="h6" gutterBottom sx={{textAlign: 'center'}}>
                                                <b>Your Iris Message Gift Code is</b>
                                            </Typography>
                                            <Typography
                                                variant="h6"
                                                sx={{
                                                    marginTop: '5px',
                                                    marginBottom: '25px',
                                                    padding: '8px',
                                                    paddingY: '6px',
                                                    width: 'fit-content',
                                                    borderStyle: 'solid',
                                                    borderWidth: '2px',
                                                    borderColor: '#193959',
                                                    marginX: 'auto',
                                                    color: '#193959'
                                                }}
                                            >
                                                <b>{giftCode}</b>
                                            </Typography>
                                            <img src={require('../media/GiftSquirrel3.png')} width='135px' alt='Squirrel under an oak tree giving an acorn to his squirrel friend'/>
                                            <Typography variant="body2" gutterBottom sx={{textAlign: 'left', marginTop: '10px'}}>
                                                This gift code is good for <b>{numMessages}</b> message(s)
                                            </Typography>
                                            <Typography variant="body2" gutterBottom sx={{textAlign: 'left'}}>
                                                How to use it:
                                                <ol style={{marginTop: '0px'}}>
                                                    <li>
                                                        Go to https://irismessage.org and write<br />something you wish to send into the universe.
                                                    </li>
                                                    <li>
                                                        Use this gift code at checkout, and our radio<br />transmitter will send your message into space.
                                                    </li>
                                                    <li>
                                                        Your message will travel through the universe for<br />millions of years!
                                                    </li>
                                                </ol>
                                                The Iris team
                                            </Typography>
                                        </div>
                                        <Typography variant="body1" gutterBottom sx={{textAlign: 'center', marginTop: '35px'}}>
                                            <img src={require('../media/Acorn.png')} width='20px' alt='Acorn icon' style={{marginRight: '5px'}} />
                                            Please ensure that you have your gift code before exiting this window.
                                        </Typography>
                                        <Stack direction="row" spacing={5} sx={{justifyContent: 'center'}}>
                                            <Button variant="contained" onClick={() => handleClose()}>
                                                I have my gift code, exit
                                            </Button>
                                        </Stack>
                                    </Fragment>
                                : giftCodeStatus === "ERROR" ? 
                                    <Fragment>
                                        <ModalExitButton onClickCallback={handleClose} />
                                        <Typography variant="h4" gutterBottom color={"#193959"} sx={{marginBottom: "15px", textAlign: 'center', marginTop: '2vh'}}>
                                            Oh no! We're sorry!
                                        </Typography>
                                        <Typography variant="body1" gutterBottom sx={{textAlign: 'center'}}>
                                            There was a problem creating your gift code. You will not be charged.
                                        </Typography>
                                        <img src={require('../media/GiftSquirrel2.png')} width='200px' alt='Squirrel under an oak tree'/>
                                    </Fragment>
                                :
                                null
                            }
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
            <TextGiftCodeModal
                modalOpen={textGiftCodeModalOpen}
                setModalOpen={setTextGiftCodeModalOpen}
                giftCode={giftCode!!}
            />
        </Fragment>
    )
}

export default GiftCodeStatusModal
