import { useState, useRef, Fragment } from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { Button, Stack, TextField } from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles'

// Augment the palette to include an ochre color
declare module '@mui/material/styles' {
    interface Palette {
      ochre: Palette['primary']
    }
  
    interface PaletteOptions {
      ochre?: PaletteOptions['primary']
    }
}
  
// Update the Button's color options to include an ochre option
declare module '@mui/material/Button' {
    interface ButtonPropsColorOverrides {
      ochre: true
    }
}
  
const theme = createTheme({
    palette: {
      ochre: {
        main: '#F2E461',
        contrastText: '#F2E461'
      }
    }
})

const darkTheme = createTheme({
    palette: {
      mode: 'dark',
      primary: {
        main: '#E3D026'
      }
    }
})

export default function OrbitSimulator() {
    const [runOrbit, setRunOrbit] = useState<ReturnType<typeof setInterval>>()

    const [initialSatHeight, setInitialSatHeight] = useState<any>("1000")
    const [initialSatVel, setInitialSatVel] = useState<any>("7.5")

    const [time, setTime] = useState<number>(0)
    const [maxAlt, setMaxAlt] = useState<string>('0')
    const [minAlt, setMinAlt] = useState<string>('0')

    const[earthHeight, setEarthHeight] = useState<string>("43.22%")
    const[earthWidth, setEarthWidth] = useState<string>("43.22%")

    const[earthLeft, setEarthLeft] = useState<string>("28.39%")
    const[earthTop, setEarthTop] = useState<string>("28.39%")
    const[satLeft, setSatLeft] = useState<string>("74.25%")
    const[satTop, setSatTop] = useState<string>("49.25%")

    const[showError, setShowError] = useState<boolean>(false)
    const[showThrust, setShowThrust] = useState<boolean>(false)
    const[thrustMessage, setThrustMessage] = useState<string>("Thruster Fired!")

    const thrust = useRef<number>(1)
    const thrustMessageTimeout = useRef<ReturnType<typeof setTimeout>>()

    const orbitSimulation = () => {
        if (!initialSatHeight || !initialSatVel || !isNumber(initialSatHeight) || !isNumber(initialSatVel) || Number(initialSatHeight) < 0) {
            setShowError(true)
            return
        }

        try {
            window.clearInterval(runOrbit)
            setShowError(false)
            setShowThrust(false)
            setThrustMessage("Thruster Fired!")
            thrust.current = 1
            thrustMessageTimeout.current = undefined
        }
        catch(TypeError) {
            //pass
        }
        finally {
            //pass
        }
    
        setTime(0)
        setMaxAlt('0')
        setMinAlt('0')
    
        var rInitial = 0
        var vInitial = 0
        rInitial = initialSatHeight * 1000 + 6.378 * Math.pow(10, 6) //m above surface of earth
        vInitial = initialSatVel * 1000 //m/s
    
        const boxWidthMeters = rInitial * 4
    
        //Set constant position of earth(p1):
        const p1 = [2 * rInitial, 2 * rInitial]
    
        //Initialize position of satilite in meters relative to top left corner of box:
        var satPos = [3 * rInitial, 2 * rInitial]
    
        var rhat = [-1.0, 0.0]
    
        //Set size of earth relative to initial separation:
        const radiusEarth = 6.378 * Math.pow(10, 6) * (25 / rInitial)  //in percent of div box
    
        setEarthHeight(2 * radiusEarth + "%")
        setEarthWidth(2 * radiusEarth + "%")
    
        setEarthLeft((p1[0] / boxWidthMeters) * 100 - radiusEarth + "%")
        setEarthTop((p1[1] / boxWidthMeters) * 100 - radiusEarth + "%")
        setSatLeft((satPos[0] / boxWidthMeters) * 100 - 0.75 + "%")
        setSatTop((satPos[1] / boxWidthMeters) * 100 - 0.75 + "%")
    
        //Initialize satilite velocity vector:
        var vSat = [0.0, -1 * vInitial]
    
        var xDir = -1
        var yDir = 1

        var ra: number
        var rb: number
    
        var r
        ra = rInitial
        rb = rInitial
    
        var theta
        var accSat
    
        var iter = 0
        var c = 0.5

        var intervalId: ReturnType<typeof setInterval>
    
        intervalId = setInterval(run, 2)
        setRunOrbit(intervalId)
        
        function run() {
            r = Math.sqrt(Math.pow(p1[0] - satPos[0], 2) + Math.pow(p1[1] - satPos[1], 2))
    
            if (r > ra) {
                ra = r
            }
    
            if (r < rb) {
                rb = r
            }
    
            setMaxAlt(((ra - 6.378 * Math.pow(10, 6))/1000).toFixed(0))
            setMinAlt(((rb - 6.378 * Math.pow(10, 6))/1000).toFixed(0))
    
            //Find new rhat vector (unit vector that points from Sat to Earth):
            if (p1[0] < satPos[0] && xDir !== -1){
                xDir = -1
            }
            else if (p1[0] > satPos[0] && xDir !== 1) {
                xDir = 1
            }
    
            if (p1[1] < satPos[1] && yDir !== -1) {
                yDir = -1
            }
            else if (p1[1] > satPos[1] && yDir !== 1) {
                yDir = 1
            }
    
            if (p1[1] !== satPos[1]) {
                theta = Math.atan(Math.abs((p1[0] - satPos[0]) / (p1[1] - satPos[1])))
                rhat[0] = Math.sin(theta) * xDir
                rhat[1] = Math.cos(theta) * yDir
            }
            else {
                rhat[0] = 1 * xDir
                rhat[1] = 0
            }
    
            //Calculate new velocity of Satilite ten seconds later:
            accSat = 398589405759999.94 / Math.pow(r, 2) //a = GM/r^2 - assumes earth mass of 5.9722E24 kg
            vSat[0] = (vSat[0] + 10 * accSat * c * rhat[0]) * thrust.current
            vSat[1] = (vSat[1] + 10 * accSat * c * rhat[1]) * thrust.current
    
            //Calculate new position of satilite:
            satPos[0] = satPos[0] + 10 * vSat[0]
            satPos[1] = satPos[1] + 10 * vSat[1]
    
            setSatLeft((satPos[0] / boxWidthMeters) * 100 - 0.75 + "%")
            setSatTop((satPos[1] / boxWidthMeters) * 100 - 0.75 + "%")
    
            if (thrust.current !== 1) {
                thrust.current = 1
    
                r = Math.sqrt(Math.pow(p1[0] - satPos[0], 2) + Math.pow(p1[1] - satPos[1], 2))
                ra = r
                rb = r
            }
    
            if (c === 0.5) {
                c = 1
            }
    
            iter++
    
            if (iter % 360 === 0) {
                setTime(iter / 360)
            }
    
            if (r < 6.378 * Math.pow(10, 6)) {
                window.clearInterval(intervalId)
                clearTimeout(thrustMessageTimeout.current)
                setThrustMessage("Satellite Crashed!")
                setShowThrust(true)
            }
        }
    }

    const handleRunSim = () => {
        orbitSimulation()
    }

    const handleStopSim = () => {
        window.clearInterval(runOrbit)
    }

    const handleSetSatAltitude = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInitialSatHeight(event.target.value)
    }

    const handleSetSatVelocity = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInitialSatVel(event.target.value)
    }

    const isNumber = (str: string) => {
        const num = Number(str);
        return !isNaN(num) && isFinite(num);
    }

    const handleThruster = (value: number) => {
        thrust.current = value
        setShowThrust(true)
        thrustMessageTimeout.current = setTimeout(() => setShowThrust(false), 850)
    }

    return (
        <Fragment>
            <Grid container spacing={3} style={{paddingLeft: '50px', paddingRight: '50px'}}>
                <Grid item xs={12}>
                    <Typography gutterBottom variant="h3" component="div" color={"#193959"}>
                        <strong>Satellite Orbit Simulator</strong>
                    </Typography>
                </Grid>

                <Grid item xs={0} sm={1} md={2}></Grid>
                <Grid item xs={12} sm={10} md={8}>
                    <Typography gutterBottom variant="body1" component="div">
                        Welcome to the Satellite Orbit Simulator, where you can see the path of a satellite as it orbits the Earth!
                    </Typography>
                    <Typography gutterBottom variant="body1" component="div">
                        Scroll down and enter a satellite's initial altitude and velocity and click Run.
                        The size of the Earth is to scale relative to the orbital path on the screen.
                        Gravity is assumed to be the only force acting on the satellite, where that force is described by the equation
                    </Typography>
                    <img src={require('../media/gravityEq.jpg')} width='200px' alt='Gravity Equation' style={{marginTop: '20px', marginBottom: '20px'}} />
                    <Typography gutterBottom variant="body1" component="div">
                        Here are a couple satellite parameters that you can plug in to see their obits:
                    </Typography>
                    <Typography gutterBottom variant="body1" component="div">
                        <strong>The International Space Station:</strong> orbits at roughly 405 km above the surface at an average velocity of
                        7.66 km/s. It takes the ISS about 90 minutes to complete each orbit around the Earth. <br />
                        <strong>Geosynchronous satellites:</strong> orbit at an altitude of 35786 km above the surface at a velocity of 3.075 km/s.
                        These satellites stay fixed above a single point on Earth and orbit once every 24 hours.
                    </Typography>
                    <Typography gutterBottom variant="body1" component="div" style={{marginTop: '20px'}}>
                        Use the Thrust buttons to fire the thruster rockets. This will increase or decrease the satellite's velocity by 10%.
                        Try and move the satellite into larger or smaller orbits using the thrusters!
                    </Typography>
                </Grid>
            <Grid item xs={0} sm={1} md={2}></Grid>
            </Grid>
            <Grid container spacing={3} style={{paddingLeft: '50px', paddingRight: '50px', paddingTop: '100px', paddingBottom: '170px', marginTop: '20px', backgroundImage: "url(https://star-maps.org/images/starsBg-wide.jpg)", backgroundSize: "cover", backgroundRepeat: "no-repeat"}}>
                <Grid item xs={12} md={4}>
                    <Typography gutterBottom variant="body1" component="div" style={{color: 'whitesmoke', fontWeight: 'bold'}}>
                        {"Elapsed Time = " + time + " hours"}
                    </Typography>
                    <Typography gutterBottom variant="body1" component="div" style={{color: 'whitesmoke', fontWeight: 'bold'}}>
                        {"Max Altitude = " + maxAlt + " km"}
                    </Typography>
                    <Typography gutterBottom variant="body1" component="div" style={{color: 'whitesmoke', fontWeight: 'bold', marginBottom: '40px'}}>
                        {"Min Altitude = " + minAlt + " km"}
                    </Typography>
                    <ThemeProvider theme={darkTheme}>
                        <TextField
                            variant="outlined"
                            margin="dense"
                            label="Initial Altitude (km)"
                            rows={1}
                            value={initialSatHeight}
                            onChange={handleSetSatAltitude}
                            sx={{marginBottom: "10px", width: '250px'}}
                        />
                        <br />
                        <TextField
                            variant="outlined"
                            margin="dense"
                            label="Initial Velocity (km/s)"
                            rows={1}
                            value={initialSatVel}
                            onChange={handleSetSatVelocity}
                            sx={{marginBottom: "30px", width: '250px'}}
                    />
                    </ThemeProvider>
                    <Stack direction="row" spacing={2} sx={{marginY: '10px'}}>
                        <Button onClick={handleRunSim} variant="contained" color="success">
                            Run
                        </Button>
                        <Button onClick={handleStopSim} variant="contained" color="error">
                            Stop
                        </Button>
                    </Stack>
                    <ThemeProvider theme={theme}>
                        <Stack direction="row" spacing={2} sx={{marginY: '10px'}}>
                            <Button onClick={() => handleThruster(1.10)} variant="outlined" color="ochre">
                                Thrust Up
                            </Button>
                            <Button onClick={() => handleThruster(0.90)} variant="outlined" color="ochre">
                                Thrust Down
                            </Button>
                        </Stack>
                    </ThemeProvider>
                    {showError ? 
                        <Typography gutterBottom variant="body1" component="div" color="#FF715E">
                            Please enter valid numbers for the initial altitude and velocity
                        </Typography>
                        : null
                    }
                </Grid>
                <Grid item xs={12} md={8}>
                    <div
                        id="orbitBox"
                        style={{
                            height: "min(87vh, 87vw)",
                            width: "min(87vh, 100%)",
                            position: "relative"
                        }}
                    >
                        {showThrust ?
                            <div
                                id="ThrustMessage"
                                style={{
                                    textAlign: "center",
                                    color: "#F2E461",
                                    paddingTop: "10%",
                                    fontSize: "14pt",
                                    fontWeight: "bold"
                                }}
                            >
                                {thrustMessage}
                            </div> :
                            null
                        }
                        <div
                            id="Earth"
                            style={{
                                height: earthHeight,
                                width: earthWidth,
                                display: "inline-block",
                                position: "absolute",
                                left: earthLeft,
                                top: earthTop
                            }}
                        >
                            <img src={require('../media/Earth.png')} alt='Earth' style={{height: "100%", width: "100%"}} />

                        </div>
                        <div
                            id="Sat"
                            style={{
                                height: "1.5%",
                                width: "1.5%",
                                backgroundColor: "whitesmoke",
                                borderRadius: "50%",
                                display: "inline-block",
                                position: "absolute",
                                left: satLeft,
                                top: satTop
                            }}
                        >
                        </div>
                    </div>
                </Grid>
            </Grid>
        </Fragment>
    )
}
