import { Routes, Route } from 'react-router'
import Home from '../pages/Home'
import Contact from '../pages/Contact'
import ErrorPage from '../pages/ErrorPage'
import GiftAMessage from '../pages/GiftAMessage'
import Science from '../pages/Science'
import OrbitSimulator from '../pages/OrbitSimulator'
import Andromeda from '../pages/Andromeda'
import StatsMessages from '../pages/StatsMessages'

function IrisRouter() {
    return (
        <Routes>
            <Route path={"/"} element={<Home />}/>
            <Route path={"/giftamessage"} element={<GiftAMessage />} />
            <Route path={"/contact"} element={<Contact />} />
            <Route path={"/science"} element={<Science />} />
            <Route path={"/science/orbitsimulator"} element={<OrbitSimulator />} />
            <Route path={"/science/andromeda"} element={<Andromeda />} />
            <Route path={"/stats/messages"} element={<StatsMessages />} />
            <Route path={"*"} element={<ErrorPage />} />
        </Routes>
    )
}

export default IrisRouter
