import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

export default function OrbitSimulator() {
    return (
        <Grid container spacing={2} style={{paddingLeft: '50px', paddingRight: '50px'}}>
            <Grid item xs={12}>
                <Typography gutterBottom variant="h3" component="div" color={"#193959"}>
                    <strong>Finding the Andromeda Galaxy</strong>
                </Typography>
            </Grid>
            <Grid item xs={0} sm={1} md={2}></Grid>
            <Grid item xs={12} sm={10} md={8}>
                <img src={require('../media/AndromedaBig.jpeg')} width='100%' alt='Andromeda galaxy' />
                <Typography gutterBottom variant="body2" component="div" color="CaptionText">
                    Photo Credit: Mike Bowen @mikeinrockandspace
                </Typography>
            </Grid>
            <Grid item xs={0} sm={1} md={2}></Grid>

            <Grid item xs={0} sm={1} md={2}></Grid>
            <Grid item xs={12} sm={10} md={8}>
                <Typography gutterBottom variant="body1" component="div">
                    At 2.5 million light years away, the Andromeda Galaxy is the furthest thing you can see with the naked eye.
                    It is also our closest galactic neighbor and resembles our own Milky Way. If you could view our own
                    galaxy from the outside, it would look very similar to Andromeda.
                </Typography>
                <Typography gutterBottom variant="body1" component="div">
                    The Andromeda Galaxy is on a collision course with the Milky Way! The galaxy is speeding toward us at
                    250,000 miles per hour. In about 4.5 billion years, the two galaxies will collide in a spectacular cosmic event.
                </Typography>
                <Typography gutterBottom variant="body1" component="div">
                    On a clear night, the galaxy is relatively easy to find and best seen in fall and winter in the northern
                    hemisphere. To find it, locate the W-shaped constellation Cassiopeia. The deeper 'V' shape of Cassiopeia
                    points toward the galaxy, which is roughly 15 degrees away, or about 1 hand width at arm's length.
                </Typography>
            </Grid>
            <Grid item xs={0} sm={1} md={2}></Grid>

            <Grid item xs={0} sm={1} md={2}></Grid>
            <Grid item xs={12}sm={10} md={8} sx={{textAlign: 'center'}}>
                <img
                    src={require('../media/AndromedaMap.jpeg')}
                    width='60%'
                    alt='Andromeda galaxy map'
                    style={{marginTop: '15px', marginBottom: '15px'}}
                />
            </Grid>
            <Grid item xs={0} sm={1} md={2}></Grid>

            <Grid item xs={0} sm={1} md={2}></Grid>
            <Grid item xs={12} sm={10} md={8}>
                <Typography gutterBottom variant="body1" component="div">
                    During the winter months, the galaxy will be in the western sky after sunset.
                    If you live in an area without light pollution, you may be able to see it with the naked eye, but it's easiest to
                    see through a pair of binoculars. It will appear as a small fuzzy patch in the sky. Happy galaxy hunting!
                </Typography>
            </Grid>
            <Grid item xs={0} sm={1} md={2}></Grid>

            <Grid item xs={0} sm={1} md={2}></Grid>
            <Grid item xs={12} sm={10} md={8} sx={{textAlign: 'center'}}>
                <img
                    src={require('../media/AndromedaBY.jpeg')}
                    width='85%'
                    alt='Andromeda galaxy'
                    style={{marginTop: '15px', marginBottom: '15px'}}
                />
            </Grid>
            <Grid item xs={0} sm={1} md={2}></Grid>
        </Grid>
    )
}
