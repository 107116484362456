import { useState, Fragment } from 'react'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import Paper from '@mui/material/Paper'
import Alert from '@mui/material/Alert'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'

import { getTransmitterHealthBalanced } from '../clients/TransmissionClient'

import GiftCodeStatusModal from '../components/GiftCodeStatusModal'

import { PayPalButtons } from "@paypal/react-paypal-js"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons"

import { GiftOrderType } from '../types/GiftOrderType'
import { postGiftOrderBalanced } from '../clients/TransmissionClient'
import { generateSourceId } from '../utils/Helpers'

import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'

export default function GiftAMessage() {
    const [numMessages, setNumMessages] = useState<number>(1)
    const [accessPattern, setAccessPattern] = useState<string>("DIRECT_PRINT")
    const [email, setEmail] = useState<string>('')
    const [retypeEmail, setRetypeEmail] = useState<string>('')
    const [validEmail, setValidEmail] = useState<boolean>(false)
    const [validRetypeEmail, setValidRetypeEmail] = useState<boolean>(false)
    const [disableContinue, setDisableContinue] = useState<boolean>(false)
    const [cost, setCost] = useState<number>(1.99)
    const [showAlert, setShowAlert] = useState<boolean>(false)
    const [alertText, setAlertText] = useState<string>()

    const [completionStep, setCompletionStep] = useState<number>(1)

    const [giftCodeStatusModalOpen, setGiftCodeStatusModalOpen] = useState<boolean>(false)
    const [giftCodeStatus, setGiftCodeStatus] = useState<string>("PENDING")
    const [giftCode, setGiftCode] = useState<string>('')

    const resetState = () => {
        setNumMessages(1)
        setAccessPattern("DIRECT_PRINT")
        setEmail('')
        setRetypeEmail('')
        setValidEmail(false)
        setValidRetypeEmail(false)
        setShowAlert(false)
        setCompletionStep(1)
        setCost(1.99)
        setGiftCode('')
        setGiftCodeStatus("PENDING")
    }

    const handleChangeNumMessages = (direction: string) => {
        if (direction === "PLUS" && numMessages <= 1000) {
            setCost(1.99 * (numMessages + 1))
            setNumMessages((numMessages) => numMessages + 1)
        } else if (direction === "MINUS" && numMessages > 1) {
            setCost(1.99 * (numMessages - 1))
            setNumMessages((numMessages) => numMessages - 1)
        }
    }

    const handleChangeAccessPattern = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAccessPattern((event.target as HTMLInputElement).value)
    }

    const validateEmail = (event: React.ChangeEvent<HTMLInputElement>, setEmailState: Function, setValidEmailState: Function) => {
        if (event.target.value.length < 250) {
            if(event.target.value.trim().length >= 5 &&
                event.target.value.includes("@") &&
                event.target.value.includes(".") &&
                event.target.value.split(".").length > 1 &&
                event.target.value.split(".")[0] !== '' &&
                event.target.value.split(".")[1] !== ''
            ) {
                setValidEmailState(true)
            } else {
                setValidEmailState(false)
            }
            setEmailState(event.target.value.trim())
        }
    }

    const updateEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
        validateEmail(event, setEmail, setValidEmail)
    }

    const updateRetypeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
        validateEmail(event, setRetypeEmail, setValidRetypeEmail)
    }

    const handleContinue = () => {
        setDisableContinue(true)
        if (accessPattern !== "EMAIL" || (validEmail && validRetypeEmail && email === retypeEmail)) {
            getTransmitterHealthBalanced().then((response) => {
                if (response === 200 || response === 201) {
                    setCompletionStep(2)
                    setShowAlert(false)
                } else {
                    setAlertText('The gift code processor is temporarily down. Please try again soon!')
                    setShowAlert(true)
                }
            })
        } else if (!validEmail) {
            setAlertText("Please enter a valid email address")
            setShowAlert(true)
        } else if (email !== retypeEmail) {
            setAlertText("Email and retyped email do not match")
            setShowAlert(true)
        } else {
            setAlertText("Please correct input fields")
            setShowAlert(true)
        }
        setDisableContinue(false)
    }

    const handleGoBack = () => {
        if (completionStep > 1) {
            setCompletionStep((step) => step - 1)
        }
    }

    const handleSubmit = (id: string) => {
        setGiftCodeStatus("PENDING")
        setGiftCodeStatusModalOpen(true)
        const data: GiftOrderType = {
            id: id,
            messageType: "TEXT",
            numMessages: numMessages,
            cost: cost,
            accessPattern: accessPattern,
            email: email,
            sourceId: generateSourceId()
        }

        const postSuccess = postGiftOrderBalanced(data).then((response) => {
            if (response.status === 200 || response.status === 201) {
                setGiftCodeStatus("SUCCESS")
                setGiftCode(response.data.giftCode)
            } else {
                setGiftCodeStatus("ERROR")
            }
        })
        return postSuccess
    }

    return (
        <Fragment>
            <Grid container spacing={1} sx={{paddingY: '1vh', paddingX: '1vw'}}>
                <Grid item xs={1} md={3}></Grid>
                <Grid item xs={10} md={6} sx={{textAlign: 'center'}}>
                    <Typography variant="h4" gutterBottom color={"#193959"} sx={{textAlign: 'center', marginBottom: '20px'}}>
                        <b>Want to give Iris as a gift?</b> <br />
                        <b>Here's how it works</b>
                    </Typography>
                    <img
                        src={require('../media/IrisGiftCard.jpeg')}
                        width='60%'
                        style={{marginBottom: '30px'}}
                        alt='Printed Gift Code'
                    />
                    <Typography variant="body1" gutterBottom>
                        <b>1.</b> Enter the number of messages you want to give as a gift
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        <b>2.</b> Choose to receive your gift code via email or print it directly from here
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        <b>3.</b> After checkout, give it to a friend! They will be able to create and send their own messages, using the gift code at checkout
                    </Typography>
                    <img
                        src={require('../media/GiftSquirrel.png')}
                        width='300px'
                        style={{paddingLeft: '3vw', marginTop: '50px', marginBottom: '20px'}}
                        alt='Squirrel next to three trees'
                    />
                </Grid>
                <Grid item xs={1} md={3}></Grid>

                <Grid item xs={1} sm={2} md={3} lg={4} xl={4}></Grid>
                {completionStep === 1 ?
                    <Grid item xs={10} sm={8} md={6} lg={4} xl={4}>
                        <Typography variant="body1" gutterBottom sx={{marginTop: '40px', marginBottom: '15px'}}>
                            <b>Number of Messages</b>
                        </Typography>
                        <Stack direction="row" spacing={7} sx={{marginBottom: '30px', textAlign: 'center'}}>
                            <IconButton aria-label="minus" onClick={() => {handleChangeNumMessages("MINUS")}} sx={{backgroundColor: '#f2f2f2'}}>
                                <FontAwesomeIcon icon={faMinus} fontSize="24"/>
                            </IconButton>
                            <Typography variant="h5" gutterBottom>
                                {numMessages}
                            </Typography>
                            <IconButton aria-label="plus" onClick={() => {handleChangeNumMessages("PLUS")}} sx={{backgroundColor: '#f2f2f2'}}>
                                <FontAwesomeIcon icon={faPlus} fontSize="24"/>
                            </IconButton>
                        </Stack>
                        <Typography variant="body1" gutterBottom sx={{marginBottom:'50px'}}>
                            {numMessages} pre-paid message(s): ${cost.toFixed(2)}
                        </Typography>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">How would you like to receive your gift code?</FormLabel>
                            <RadioGroup aria-label="access_pattern" name="access_pattern_select" value={accessPattern} onChange={handleChangeAccessPattern}>
                                <FormControlLabel value="DIRECT_PRINT" control={<Radio />} label="Print from here" />
                                <FormControlLabel value="EMAIL" control={<Radio />} label="Email" />
                            </RadioGroup>
                        </FormControl>
                        {
                            accessPattern === "EMAIL" ?
                                <Fragment>
                                    <Typography variant="body1" gutterBottom>
                                        <b>Email</b>
                                    </Typography>
                                    <TextField
                                        helperText={!validEmail ? "*Please enter a valid email" : null}
                                        variant="standard"
                                        margin="dense"
                                        id="iris-message-email"
                                        label="Email"
                                        rows={1}
                                        value={email}
                                        onChange={updateEmail}
                                        sx={{marginBottom: "50px", width: '300px'}}
                                    />
                                    <Typography variant="body1" gutterBottom>
                                        <b>Retype Email</b>
                                    </Typography>
                                    <TextField
                                        helperText={!validRetypeEmail ? "*Please enter a valid email" : null}
                                        variant="standard"
                                        margin="dense"
                                        id="iris-message-retype-email"
                                        label="Retype Email"
                                        rows={1}
                                        value={retypeEmail}
                                        onChange={updateRetypeEmail}
                                        sx={{width: '300px'}}
                                    />
                                </Fragment> : null
                        }
                        {
                            accessPattern === "EMAIL" ?
                            <Typography variant="body2" gutterBottom sx={{marginBottom:'20px', marginTop: '50px'}}>
                                We do not send promotions and never save your email. <br />
                                Gift codes are guaranteed for at least one year after time of purchase.
                            </Typography> :
                            <Typography variant="body2" gutterBottom sx={{marginBottom:'20px', marginTop: '50px'}}>
                                Gift codes are guaranteed for at least one year after time of purchase.
                            </Typography>
                        }
                        {showAlert ? <Alert severity="error" sx={{marginTop: '20px', marginBottom: '20px'}}>{alertText}</Alert> : null}
                        <Button
                            variant="contained"
                            onClick={handleContinue}
                            disabled={disableContinue}
                            sx={{marginBottom: '30px', marginRight: '25px'}}
                        >
                            Continue
                        </Button>
                    </Grid>
                : completionStep === 2 ?
                    <Grid item xs={10} sm={8} md={6} lg={4} xl={4}>
                        <Paper elevation={0} sx={{ padding: '18px', marginBottom: '20px', borderStyle: 'solid', borderWidth: '1px', borderColor: 'lightgrey'}}>
                            <Grid container spacing={1}>
                                <Grid item xs={12} sx={{textAlign: "center"}}>
                                    <Typography variant="h6" sx={{color: '#193959'}}>
                                        Your gift order is ready!
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Paper sx={{backgroundColor: '#f9f9f9', marginBottom: '30px', marginTop: '10px', padding: '10px'}}>
                                <Grid container spacing={1}>
                                    <Grid item xs={2} sx={{display: 'grid', placeItems: 'center', paddingBottom: '3%'}}>
                                        <img src={require('../media/Iris-Logo.png')} width='40px' alt='Iris Logo' />
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography variant="body1" gutterBottom>
                                            <b>{numMessages}</b> pre-paid message{numMessages > 1 ? 's' : null} to the universe
                                        </Typography>
                                        {
                                            accessPattern === "EMAIL" ?
                                            <Typography variant="body2" gutterBottom>
                                                <b>Shipping:</b> Gift code will be sent to {email}
                                            </Typography> :
                                            <Typography variant="body2" gutterBottom>
                                                <b>Shipping:</b> Gift code will be printable on next screen
                                            </Typography>
                                        }
                                    </Grid>
                                    <Grid item xs={2} sx={{textAlign: 'right'}}>
                                        <Typography
                                            variant="body2"
                                            gutterBottom
                                            sx={{paddingY:'16px'}}
                                            >
                                            <b>${cost.toFixed(2)}</b>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Paper>
                            <Grid container spacing={1} sx={{paddingX: '10px'}}>
                                <Grid item xs={10}></Grid>
                                <Grid item xs={2} sx={{textAlign: 'right'}}>
                                    <Typography
                                        variant="body2"
                                        gutterBottom
                                        >
                                        <b>${cost.toFixed(2)} total</b>
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={1}>
                                <Grid item xs={12} sx={{textAlign: "center", paddingBottom: '25px', marginBottom: '25px', borderBottomStyle: 'solid', borderBottomWidth: '0.5px'}}>
                                    <img src={require('../media/GiftSquirrel2.png')} width='30%' alt='Squirrel holding an acorn under a tree'/>
                                </Grid>
                            </Grid>
                            <PayPalButtons
                                createOrder={(data, actions) => {
                                    return actions.order.create({
                                        purchase_units: [
                                            {
                                                amount: {
                                                    value: cost.toFixed(2),
                                                    currency_code: "USD"
                                                }
                                            }
                                        ],
                                        intent: "AUTHORIZE"
                                    })
                                }}
                                onApprove={(data, actions) => {
                                    return actions!!.order!!.authorize().then((details) => {
                                        handleSubmit(data.orderID)
                                    })
                                }}
                            />
                            <Grid container spacing={1}>
                                <Grid item xs={12} sx={{textAlign: "center"}}>
                                    <Button
                                        variant="text"
                                        onClick={handleGoBack}
                                        sx={{marginBottom: '5px'}}
                                    >
                                        Back
                                    </Button>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                : null}
                <Grid item xs={1} sm={2} md={3} lg={4} xl={4}></Grid>
            </Grid>
            <GiftCodeStatusModal
                modalOpen={giftCodeStatusModalOpen}
                setModalOpen={setGiftCodeStatusModalOpen}
                giftCodeStatus={giftCodeStatus}
                giftCode={giftCode}
                numMessages={numMessages}
                resetState={resetState}
            />
        </Fragment>
    )
}
