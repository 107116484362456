import { useNavigate } from 'react-router'

import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import CardActionArea from '@mui/material/CardActionArea'
import Typography from '@mui/material/Typography'

import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'

export default function Science() {
    const navigate = useNavigate()

    return (
        <Grid container spacing={4} style={{paddingLeft: '50px', paddingRight: '50px'}}>
            <Grid item xs={12}>
                <Typography variant="h3" gutterBottom color={"#193959"} sx={{marginBottom: '0px'}}>
                    <strong>Learn About Astrophysics!</strong>
                </Typography>
                <Typography variant="h5" gutterBottom color={"#193959"}>
                    (Don't worry, it's fun)
                </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <Card>
                    <CardActionArea onClick={() => navigate("/science/andromeda")}>
                        <CardMedia
                            component="img"
                            width="100%"
                            image={require("../media/Andromeda.jpeg")}
                            alt="Andromeda galaxy"
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                Finding the Andromeda Galaxy
                            </Typography>
                            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                Learn how to find the Andromeda galaxy in your backyard with just a pair of binoculars.
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <Card>
                    <CardActionArea onClick={() => navigate("/science/orbitsimulator")}>
                        <CardMedia
                            component="img"
                            width="100%"
                            image={require("../media/OrbitSimulator.jpeg")}
                            alt="satilite orbiting the earth"
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                Satellite Orbit Simulator
                            </Typography>
                            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                Take the controls of a satellite as it orbits the Earth to learn about Newton's Law of Gravitation and orbital mechanics.
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>
            </Grid>
        </Grid>
    )
}
