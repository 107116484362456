import { BaseSyntheticEvent, useState, Fragment, useEffect } from 'react'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import Link from '@mui/material/Link'
import Alert from '@mui/material/Alert'

import EnterGiftCodeModal from '../components/EnterGiftCodeModal'
import TransmissionModal from '../components/TransmissionModal'
import ConnectingModal from '../components/ConnectingModal'
import TransmissionErrorModal from '../components/TransmissionErrorModal'
import FAQ from '../components/FAQ'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faEnvelopeCircleCheck, faGift } from '@fortawesome/free-solid-svg-icons'

import { getTransmitterHealthBalanced } from '../clients/TransmissionClient'
import { postOrderBalanced } from '../clients/TransmissionClient'
import { generateSourceId } from '../utils/Helpers'
import { OrderType } from '../types/OrderType'

import { PayPalButtons } from "@paypal/react-paypal-js"
import { isMobile } from 'react-device-detect'

import ParachuteSquirrel from '../media/ParachuteSquirrel.mp4'

import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import './Home.css'

export default function Home() {
    const [message, setMessage] = useState<string>('')
    const [charsLeft, setCharsLeft] = useState<number>(350)
    const [messageType, setMessageType] = useState<string>('TEXT')
    const [charity, setCharity] = useState<string | undefined>('')
    const [showAlert, setShowAlert] = useState<boolean>(false)
    const [showEmailAlert, setShowEmailAlert] = useState<boolean>(false)
    const [alertText, setAlertText] = useState<string>()
    const [disableContinue, setDisableContinue] = useState<boolean>(false)

    const [email, setEmail] = useState<string>('')
    const [validEmail, setValidEmail] = useState<boolean>(true)

    const [successMessage, setSuccessMessage] = useState<string>()
    const [orderId, setOrderId] = useState<string>()
    const [completionStep, setCompletionStep] = useState<number>(1)

    const [giftCodeModalOpen, setGiftCodeModalOpen] = useState<boolean>(false)
    const [transmissionModalOpen, setTransmissionModalOpen] = useState<boolean>(false)
    const [transmissionErrorModalOpen, setTransmissionErrorModalOpen] = useState<boolean>(false)
    const [connectingModalOpen, setConnectingModalOpen] = useState<boolean>(false)

    const [parachuteSquirrelPlayed, setParachuteSquirrelPlayed] = useState<boolean>(false)

    useEffect(() => {
        setTimeout(() => {
            sessionStorage.setItem("parachuteSquirrel", "true")
            setParachuteSquirrelPlayed(true)
        }, 2750)
    })

    const charityEnumMap = new Map<string, string>()
    charityEnumMap.set("Humane Society US", "HUMANE_SOCIETY")
    charityEnumMap.set("Habitat for Humanity", "HABITAT_FOR_HUMANITY")
    charityEnumMap.set("The Nature Conservancy", "THE_NATURE_CONSERVANCY")
    charityEnumMap.set("Alzheimer's Association", "ALZHEIMERS_ASSOCIATION")

    const charityEnumDisplayMap = new Map<string, string>()
    charityEnumDisplayMap.set("HUMANE_SOCIETY", "Humane Society US")
    charityEnumDisplayMap.set("HABITAT_FOR_HUMANITY", "Habitat for Humanity")
    charityEnumDisplayMap.set("THE_NATURE_CONSERVANCY", "The Nature Conservancy")
    charityEnumDisplayMap.set("ALZHEIMERS_ASSOCIATION", "Alzheimer's Association")

    const clearPage = () => {
        setMessage('')
        setCharsLeft(350)
        setMessageType('TEXT')
        setCharity('')
        setEmail('')
        setValidEmail(true)
        setShowEmailAlert(false)
        setShowAlert(false)
        setSuccessMessage('')
        setOrderId('')
        setCompletionStep(1)
    }

    const updateMessage = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value.length <= 350) {
            setMessage(event.target.value)
            setCharsLeft(350 - event.target.value.length)
        }
    }

    const handleContinue = () => {
        if (message.trim()) {
            setDisableContinue(true)
            getTransmitterHealthBalanced().then((status) => {
                if (status === 200 || status === 201) {
                    if (message.length <= 350) {
                        setCompletionStep(2)
                        setShowAlert(false)
                        setDisableContinue(false)
                    } else {
                        setAlertText('Message cannot be longer than 350 characters!')
                        setShowAlert(true)
                        setDisableContinue(false)
                    }
                } else {
                    setAlertText('Message transmitter is temporarily down. Please try again soon!')
                    setShowAlert(true)
                    setDisableContinue(false)
                }
            })
        } else {
            setAlertText('Please enter a message to send!')
            setShowAlert(true)
        }
    }

    const handleCharityButton = (event: BaseSyntheticEvent) => {
        if (charity === charityEnumMap.get(event.target.innerText)) {
            setCharity('')
        } else {
            setCharity(charityEnumMap.get(event.target.innerText))
        }
    }

    const handleGoBack = () => {
        if (completionStep > 1) {
            setCompletionStep((step) => step - 1)
        }
    }

    const handleNext = () => {
        if (completionStep === 2) {
            if (validEmail) {
                setCompletionStep(3)
                setShowEmailAlert(false)
            } else {
                setShowEmailAlert(true)
            }
        }
    }

    const updateEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value.length < 250) {
            if((event.target.value.trim().length >= 5 &&
                event.target.value.includes("@") &&
                event.target.value.includes(".") &&
                !event.target.value.includes(" ") &&
                event.target.value.split(".").length > 1 &&
                event.target.value.split(".")[0] !== '' &&
                event.target.value.split(".")[1] !== ''
            ) || event.target.value.trim().length === 0) {
                setValidEmail(true)
            } else {
                setValidEmail(false)
            }
            setEmail(event.target.value.trim())
        }
    }

    const handleSubmit = (id: string) => {
        setConnectingModalOpen(true)
        const cost = messageType === 'HAND_WRITTEN' ? 2.99 : 1.99
        const data: OrderType = {
            id: id,
            message: message,
            messageType: messageType,
            charity: charity,
            cost: cost,
            email: email,
            sourceId: generateSourceId()
        }

        const postSuccess = postOrderBalanced(data).then((response) => {
            if (response.status === 200 || response.status === 201) {
                setOrderId(response.data?.orderId)
                if (response.data?.message) {
                    setSuccessMessage(response.data.message)
                }

                setConnectingModalOpen(false)
                setTransmissionModalOpen(true)
            } else {
                setConnectingModalOpen(false)
                setTransmissionErrorModalOpen(true)
            }
        })
        return postSuccess
    }

    return (
        !parachuteSquirrelPlayed && !isMobile && !sessionStorage.getItem("parachuteSquirrel") ?
            <Grid container spacing={1}>
                <Grid item xs={0} lg={1}></Grid>
                <Grid item xs={12} lg={10}>
                    <video autoPlay muted width='100%'>
                        <source src={ParachuteSquirrel} type='video/mp4' />
                    </video>
                </Grid>
                <Grid item xs={0} lg={1}></Grid>
            </Grid>
        :
        <Fragment>
            <Grid container spacing={1}>
                <Grid item xs={1} md={3}></Grid>
                <Grid item xs={10} md={6} sx={{textAlign: "center"}}>
                    <Typography className='headingBig' variant="h3" gutterBottom color={"#193959"} sx={{textAlign: 'center'}}>
                        <b>Send a message into space</b>
                    </Typography>
                    <Typography className='headingMed' variant="h4" gutterBottom color={"#193959"} sx={{textAlign: 'center'}}>
                        <b>Send a message into space</b>
                    </Typography>
                    <Typography variant="h5" gutterBottom sx={{textAlign: 'center'}}>
                        Your personal message will be sent into space using pulsed radio waves that are able to
                        pass through the clouds and travel the universe for millions of years.
                    </Typography>
                </Grid>
                <Grid item xs={1} md={3}></Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <img
                        src={require('../media/Background.png')}
                        width='100%'
                        alt='irismessage.org background showing trees, squirrels, UFOs and a radio tower'
                        className='IrisBackground'
                    />
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs={1} sm={2} md={3} lg={4} xl={4}></Grid>
                {completionStep === 1 ?
                    <Grid item xs={10} sm={8} md={6} lg={4} xl={4}>
                        <TextField
                            fullWidth
                            margin="dense"
                            value={message}
                            id="iris-message-text"
                            label="Type your message here!"
                            multiline
                            rows={8}
                            onChange={updateMessage}
                        />
                        <Grid container>
                            <Grid item xs={11}></Grid>
                            <Grid item xs={1}>
                                <Typography variant="body2" gutterBottom sx={{textAlign: 'right', marginRight: '5px'}}>
                                    {charsLeft}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Typography variant="body1" gutterBottom>
                            1 message sent into space: $1.99
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            Want to give messages as a gift instead? <FontAwesomeIcon icon={faGift} style={{color: "#193959"}} fontSize="16"/> <Link href="/giftamessage">Gift a Message</Link>
                        </Typography>
                        <Stack direction="row" spacing={7} sx={{marginY: '30px', justifyContent: 'center'}}>
                            <img
                                src={require('../media/Acorn.png')}
                                width='20px'
                                alt='Acorn'
                            />
                            <img
                                src={require('../media/Acorn.png')}
                                width='20px'
                                alt='Acorn'
                            />
                            <img
                                src={require('../media/Acorn.png')}
                                width='20px'
                                alt='Acorn'
                            />
                        </Stack>
                        <Grid container spacing={1}>
                            <Grid item xs={6} sm={3}>
                                <Button
                                    variant="text"
                                    onTouchEnd={(event) => {
                                        handleCharityButton(event)
                                        event.preventDefault()
                                    }}
                                    onClick={handleCharityButton}
                                    sx={{
                                        marginTop: '6px',
                                        marginBottom: '8px',
                                        display: 'block',
                                        textTransform: 'none',
                                        backgroundColor: charity === 'THE_NATURE_CONSERVANCY' ? '#ffffff' : '#f2f2f2',
                                        color: '#123e6b',
                                        width: '100%',
                                        cursor: 'pointer',
                                        aspectRatio: '1/1',
                                        borderWidth: charity === 'THE_NATURE_CONSERVANCY' ? '2.5px' : '0pt',
                                        borderStyle: 'solid',
                                        borderColor: '#123e6b'
                                    }}
                                >
                                    The Nature Conservancy
                                </Button>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Button
                                    variant="text"
                                    onTouchEnd={(event) => {
                                        handleCharityButton(event)
                                        event.preventDefault()
                                    }}
                                    onClick={handleCharityButton}
                                    sx={{
                                        marginTop: '6px',
                                        marginBottom: '8px',
                                        display: 'block',
                                        textTransform: 'none',
                                        backgroundColor: charity === 'HUMANE_SOCIETY' ? '#ffffff' : '#f2f2f2',
                                        color: '#123e6b',
                                        width: '100%',
                                        cursor: 'pointer',
                                        aspectRatio: '1/1',
                                        borderWidth: charity === 'HUMANE_SOCIETY' ? '2.5px' : '0pt',
                                        borderStyle: 'solid',
                                        borderColor: '#123e6b'
                                    }}
                                >
                                    Humane Society US
                                </Button>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Button
                                    variant="text"
                                    onTouchEnd={(event) => {
                                        handleCharityButton(event)
                                        event.preventDefault()
                                    }}
                                    onClick={handleCharityButton}
                                    sx={{
                                        marginTop: '6px',
                                        marginBottom: '8px',
                                        display: 'block',
                                        textTransform: 'none',
                                        backgroundColor: charity === 'HABITAT_FOR_HUMANITY' ? '#ffffff' : '#f2f2f2',
                                        color: '#123e6b',
                                        width: '100%',
                                        cursor: 'pointer',
                                        aspectRatio: '1/1',
                                        borderWidth: charity === 'HABITAT_FOR_HUMANITY' ? '2.5px' : '0pt',
                                        borderStyle: 'solid',
                                        borderColor: '#123e6b'
                                    }}
                                >
                                    Habitat for Humanity
                                </Button>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Button
                                    variant="text"
                                    onTouchEnd={(event) => {
                                        handleCharityButton(event)
                                        event.preventDefault()
                                    }}
                                    onClick={handleCharityButton}
                                    sx={{
                                        marginTop: '6px',
                                        marginBottom: '8px',
                                        display: 'block',
                                        textTransform: 'none',
                                        backgroundColor: charity === 'ALZHEIMERS_ASSOCIATION' ? '#ffffff' : '#f2f2f2',
                                        color: '#123e6b',
                                        width: '100%',
                                        cursor: 'pointer',
                                        aspectRatio: '1/1',
                                        borderWidth: charity === 'ALZHEIMERS_ASSOCIATION' ? '2.5px' : '0pt',
                                        borderStyle: 'solid',
                                        borderColor: '#123e6b'
                                    }}
                                >
                                    Alzheimer's Association
                                </Button>
                            </Grid>
                        </Grid>
                        {
                            charity ?
                            <Typography variant="body1" gutterBottom>
                                20% goes to <em>{charityEnumDisplayMap.get(charity)}</em>
                            </Typography> :
                            <Typography variant="body1" gutterBottom>
                                Make a selection above if you would like 20% of the profits from your message donated to a non-profit of your choice.
                            </Typography>
                        }
                        {showAlert ? <Alert severity="error" sx={{marginTop: '20px', marginBottom: '20px'}}>{alertText}</Alert> : null}
                        <Button
                            variant="contained"
                            onClick={handleContinue}
                            disabled={disableContinue}
                            sx={{marginTop: '15px'}}
                        >
                            Continue
                        </Button>
                    </Grid>
                : completionStep === 2 ?
                    <Grid item xs={10} sm={8} md={6} lg={4} xl={4}>
                        <Paper elevation={0} sx={{ padding: '18px', marginBottom: '20px', borderStyle: 'solid', borderWidth: '1px', borderColor: 'lightgrey'}}>
                            <Grid container spacing={1}>
                                <Grid item xs={12} sx={{textAlign: "center"}}>
                                    <Typography variant="h6" sx={{color: '#193959'}}>
                                        Your message is ready to send!
                                    </Typography>
                                    <Typography variant="subtitle1" gutterBottom>
                                        Connected to the radio transmitter <FontAwesomeIcon icon={faCheck} style={{color: "#209310"}} fontSize="16"/>
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Typography variant="body1" sx={{marginTop: '20px'}} gutterBottom>
                                <b>(Optional)</b>
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                If you would like a free transmission certificate when your message goes to space, enter your email below
                            </Typography>
                            <TextField
                                helperText={!validEmail ? "*Please enter a valid email, or leave blank" : null}
                                fullWidth
                                margin="dense"
                                id="iris-message-text"
                                label="Email, optional"
                                rows={1}
                                value={email}
                                onChange={updateEmail}
                                sx={{marginBottom: "5px"}}
                            />
                            <Typography variant="body2" gutterBottom>
                                *We do not send promotions and never save your email
                            </Typography>
                            {showEmailAlert ? <Alert severity="error">Please enter a valid email or leave blank</Alert> : null}
                            <Grid container spacing={1}>
                                <Grid item xs={12} sx={{textAlign: "center"}}>
                                    <Button
                                        variant="contained"
                                        onClick={handleNext}
                                        sx={{marginBottom: '5px', marginTop: '20px'}}
                                    >
                                        Next
                                    </Button>
                                </Grid>
                                <Grid item xs={12} sx={{textAlign: "center"}}>
                                    <Button
                                        variant="text"
                                        onClick={handleGoBack}
                                        sx={{marginBottom: '5px'}}
                                    >
                                        Back
                                    </Button>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                : completionStep === 3 ?
                    <Grid item xs={10} sm={8} md={6} lg={4} xl={4}>
                        <Paper elevation={0} sx={{ padding: '18px', marginBottom: '20px', borderStyle: 'solid', borderWidth: '1px', borderColor: 'lightgrey'}}>
                            <Grid container spacing={1}>
                                <Grid item xs={12} sx={{textAlign: "center"}}>
                                    <Typography variant="h6" sx={{color: '#193959'}}>
                                        Your message is ready to send!
                                    </Typography>
                                    <Typography variant="subtitle1" gutterBottom>
                                        Connected to the radio transmitter <FontAwesomeIcon icon={faCheck} style={{color: "#209310"}} fontSize="16"/>
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Paper sx={{backgroundColor: '#f9f9f9', marginBottom: '30px', marginTop: '10px', padding: '10px'}}>
                                <Grid container spacing={1}>
                                    <Grid item xs={2} sx={{display: 'grid', placeItems: 'center', paddingBottom: '3%'}}>
                                        <img src={require('../media/Iris-Logo.png')} width='40px' alt='Iris Logo' />
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography variant="body1" gutterBottom>
                                            1 message to the universe
                                        </Typography>
                                        <Typography variant="body2" gutterBottom>
                                            <b>Message:</b> {message}
                                        </Typography>
                                        {charity ?
                                            <Typography variant="body2" gutterBottom sx={{marginY:'10px'}}>
                                                <b>20% Charity:</b> {charity}
                                            </Typography>
                                            : null
                                        }
                                        <Typography variant="body2" gutterBottom sx={{marginY:'10px'}}>
                                            <b>Shipping:</b> The speed of light (670,616,629 mph), direct to the universe using a 12 MHz radio transmitter
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2} sx={{textAlign: 'right'}}>
                                        <Typography
                                            variant="body2"
                                            gutterBottom
                                            sx={{paddingY:'16px'}}
                                            >
                                            <b>${messageType === "HAND_WRITTEN" ? "2.99" : "1.99"}</b>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Paper>
                            {email ?
                                <Paper sx={{backgroundColor: '#f9f9f9', marginBottom: '30px', marginTop: '10px', padding: '10px'}}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={2} sx={{display: 'grid', placeItems: 'center', paddingBottom: '1%'}}>
                                            <FontAwesomeIcon icon={faEnvelopeCircleCheck} style={{color: "#193959"}} fontSize="35"/>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Typography variant="body1" gutterBottom>
                                                Transmission Certificate
                                            </Typography>
                                            <Typography variant="body2" gutterBottom sx={{marginY:'10px'}}>
                                                <b>Emailed to:</b> {email}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={2} sx={{textAlign: 'right'}}>
                                            <Typography
                                                variant="body2"
                                                gutterBottom
                                                sx={{paddingY:'16px'}}
                                                >
                                                <b>Free</b>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Paper>
                                : null
                            }
                            <Grid container spacing={1} sx={{paddingX: '10px'}}>
                                <Grid item xs={10}></Grid>
                                <Grid item xs={2} sx={{textAlign: 'right'}}>
                                    <Typography
                                        variant="body2"
                                        gutterBottom
                                        >
                                        <b>${messageType === "HAND_WRITTEN" ? "2.99" : "1.99"} total</b>
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={1}>
                                <Grid item xs={12} sx={{textAlign: "center", marginBottom: '25px', borderBottomStyle: 'solid', borderBottomWidth: '0.5px'}}>
                                    <img src={require('../media/PaymentRocket.png')} width='50%' className='SquirrelRocket' alt='Squirrel boarding a rocket'/>
                                </Grid>
                            </Grid>
                            <Button
                                variant="contained"
                                onClick={() => setGiftCodeModalOpen(true)}
                                sx={{marginBottom: '30px', width: '100%'}}
                            >
                                Use Gift Code
                            </Button>
                            <PayPalButtons
                                createOrder={(data, actions) => {
                                    return actions.order.create({
                                        purchase_units: [
                                            {
                                                amount: {
                                                    value: messageType === "HAND_WRITTEN" ? "2.99" : "1.99",
                                                    currency_code: "USD"
                                                }
                                            }
                                        ],
                                        intent: "AUTHORIZE"
                                    })
                                }}
                                onApprove={(data, actions) => {
                                    return actions!!.order!!.authorize().then((details) => {
                                        handleSubmit(data.orderID)
                                    })
                                }}
                            />
                            <Grid container spacing={1}>
                                <Grid item xs={12} sx={{textAlign: "center"}}>
                                    <Button
                                        variant="text"
                                        onClick={handleGoBack}
                                        sx={{marginBottom: '5px'}}
                                    >
                                        Back
                                    </Button>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                : null}
                <Grid item xs={1} sm={2} md={3} lg={4} xl={4}></Grid>
            </Grid>
            {completionStep === 1 ?
                <Fragment>
                    <Grid container spacing={1}>
                        <Grid item xs={1} md={2} lg={3}></Grid>
                        <Grid item xs={10} md={8} lg={6}>
                            <Stack direction="row" spacing={7} sx={{marginBottom: '50px', marginTop: '10px', justifyContent: 'center'}}>
                                <img
                                    src={require('../media/SquirrelFAQ.png')}
                                    width='300px'
                                    alt='Squirrel under a radio tower with a question mark over his head'
                                />
                            </Stack>
                            <FAQ 
                                question='Wait, what is Iris Message?'
                                answer="Iris is a way to beam your own messages into space using radio waves. Iris Message is the opposite of social media.
                                Your message will remain completely anonymous, and will not get any likes or comments. If you
                                want to tell your friends, great! If not, even better!"
                            />
                            <FAQ 
                                question='Will my message really travel the universe for millions of years?!'
                                answer="Yes! The radio waves carrying your message will pass through the Earth's atmosphere into space."
                                htmlText={<b>Even though the signal will become extremely weak over time and below what we could reasonably detect, the radio waves will still exist and travel through the universe indefinitely.</b>}
                            />
                            <FAQ 
                                question='How does Iris work?'
                                answer={`Your message will be sent into space using our 27 MHz CB radio transmitter in Saint Paul, Minnesota. The message is embedded into a series of pulsed radio waves, similar to Morse Code. This specific frequency of radio waves is within a range of frequencies known as the "Radio Window", which are able to pass through the Earth's atmosphere into space. Learn more about radio waves from `}
                                htmlText={<Link href="https://www.nasa.gov/directorates/somd/space-communications-navigation-program/radio-vs-optical-spectrum/#:~:text=A%20radio%20wave%20is%20generated,energy%20from%20space" target="_blank" rel="noreferrer">NASA Space Communications</Link>}
                            />
                            <Stack direction="row" spacing={7} sx={{marginBottom: '50px', marginTop: '20px', justifyContent: 'center'}}>
                                <img
                                    src={require('../media/RadioWindow.jpg')}
                                    width='90%'
                                    alt='Radio Window frequencies'
                                />
                            </Stack>
                            <FAQ 
                                question='What kind of messages can I send?'
                                answer='Anything! As long as it is not hateful or in violation of US laws. It can be as profound or mundane as you want.
                                It could be a message about a family member. Or a message about a pet. Or maybe you are just really mad at your boss and
                                want the universe to know about it for the rest of eternity.'
                            />
                            <FAQ 
                                question='Will anyone read my message?'
                                answer='No. The message sending process is completely automated and no one will read your message.'
                            />
                            <FAQ 
                                question='Why is it called Iris?'
                                answer='The name comes from Greek mythology, where Iris is the goddess who delivers messages to and from the gods.'
                            />
                        </Grid>
                        <Grid item xs={1} md={2} lg={3}></Grid>
                    </Grid>
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Typography variant="h4" gutterBottom color={"#193959"} sx={{textAlign: 'center', marginTop: '70px'}}>
                                <b>Our Team</b>
                            </Typography>
                        </Grid>

                        <Grid item xs={0} sm={3} md={3} lg={3}></Grid>
                        <Grid item xs={12} sm={2} md={2} lg={2}>
                            <img
                                src={require('../media/Erik-Arecibo.jpeg')}
                                width='100%'
                                alt='Person at the Arecibo telescope'
                            />
                            <Typography variant="body1" gutterBottom sx={{textAlign: 'center'}}>
                                <strong>Erik</strong> <br /> Astrophysicist, Macalester College Grad, Founder
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={2} md={2} lg={2}>
                            <img
                                src={require('../media/George.jpeg')}
                                width='100%'
                                alt='Dog with floppy ears coming out of the water'
                            />
                            <Typography variant="body1" gutterBottom sx={{textAlign: 'center'}}>
                                <strong>George</strong> <br /> Support
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={2} md={2} lg={2}>
                            <img
                                src={require('../media/NeighborSquirrel.jpeg')}
                                width='100%'
                                alt='Squirrel posing for a portrait'
                            />
                            <Typography variant="body1" gutterBottom sx={{textAlign: 'center'}}>
                                <strong>Neighborhood Squirrels</strong> <br /> Marketing, Walnut Collections, High-voltage Electrical Engineering
                            </Typography>
                        </Grid>
                        <Grid item xs={0} sm={3} md={3} lg={3}></Grid>
                    </Grid>
                </Fragment> :
                null
            }
            <EnterGiftCodeModal
                modalOpen={giftCodeModalOpen}
                setModalOpen={setGiftCodeModalOpen}
                setTransmissionModalOpen={setTransmissionModalOpen}
                setTransmissionErrorModalOpen={setTransmissionErrorModalOpen}
                message={message}
                messageType={messageType}
                charity={charity}
                email={email}
                setSuccessMessage={setSuccessMessage}
                setOrderId={setOrderId}
            />
            <ConnectingModal
                modalOpen={connectingModalOpen}
            />
            <TransmissionModal
                modalOpen={transmissionModalOpen}
                setModalOpen={setTransmissionModalOpen}
                successMessage={successMessage}
                orderId={orderId}
                clearHomepage={clearPage}
            />
            <TransmissionErrorModal
                modalOpen={transmissionErrorModalOpen}
                setModalOpen={setTransmissionErrorModalOpen}
                setMessage={setMessage}
                setCharsLeft={setCharsLeft}
                setMessageType={setMessageType}
                setCharity={setCharity}
            />
        </Fragment>
    )
}
